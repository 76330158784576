<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("permissions", pageName, pageName) }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('permissions', pageName, 'search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfPermissions && listOfPermissions.length"
    >
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="permission in listOfPermissions"
        :key="permission.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editPermission(permission)"
        >
          <span class="label">{{ permission.name }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import permissionsMixin from "@/services/mixins/permissions/permissions-mixin";
export default {
  name: "Permissions",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    this.fetchPermissions();
  },
  computed: {
    ...mapState("permissions", ["permissions", "selectedPermission"]),
    listOfPermissions() {
      const query = this.searchQuery;
      if (
        this.permissions &&
        this.permissions.data &&
        this.permissions.data.length
      ) {
        if (query && query.length > 1) {
          return this.permissions.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.permissions.data;
        }
      }
      return null;
    },
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "user-permissions";
      } else if (containerType === "RESOURCE") {
        return "resource-permissions";
      } else if (containerType === "LEVEL") {
        return "level-permissions";
      }
      return "";
    }
  },
  methods: {
    ...mapActions("permissions", ["setSelectedPermission"]),
    editPermission(permission) {
      this.setSelectedPermission(permission);
      setTimeout(() => {
        this.$router.push({ name: this.editRouteName });
      }, 0);
    },
    selectPermission(permission) {
      this.setSelectedPermission(permission);
    }
  },
  watch: {
    containerTypeId() {
      this.fetchPermissions();
    }
  },
  mixins: [permissionsMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
